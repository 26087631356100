import { availableLanguagesForButtonCalendar } from "@helpers/defaults";
import {
  select,
  createElement,
  checkIfVarExist,
  getPathForVapor,
  svgIcon,
} from "@utils/function";

export default function ProgrammeCreateRow() {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
  const programmeContent = select(".programme-content .slider-content");

  if (!programmeContent) return;

  programmeContent.textContent = "";

  const wrapper = createElement("div");
  wrapper.className = "programme-wrapper";

  const agendaToday = HQ.Properties.agendaToday;
  const agendaFuture = HQ.Properties.agendaFuture;

  if (HQ.Properties.isSocialMediaBot) return;

  if (checkIfVarExist(agendaToday)) {
    let agendaTodayTemplate = createTodayFutureRow("today", agendaToday);
    wrapper.insertAdjacentHTML("beforeend", agendaTodayTemplate);
  }

  if (checkIfVarExist(agendaFuture)) {
    let agendaFutureTemplate = createTodayFutureRow("future", agendaFuture);
    wrapper.insertAdjacentHTML("beforeend", agendaFutureTemplate);
  }

  programmeContent.insertAdjacentElement("beforeend", wrapper);
}

function createTodayFutureRow(agendaType, todayFutureArray) {
  if (todayFutureArray.length <= 0) return;

  const optionsForDay = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const checkIfParnerExistAndHaveImage = !!todayFutureArray.find(
    (obj) => obj?.partner?.logo != null
  );
  const programmeRow = todayFutureArray
    // .sort((a, b) => a.dateandtime.localeCompare(b.dateandtime))
    .map(
      (
        {
          id,
          dateandtime,
          dateandtime_end,
          description,
          title,
          place_id,
          partner,
        },
        index,
        array
      ) => {
        const showOnMap = place_id ? true : false;

        // get small logo from parner
        const logoSmall = partner?.logo_positive;

        // the number of columns depends on the
        // agenda variable Show Logo
        let titleColumnNumber = HQ.Properties.agendaShowLogo == "0" ? 8 : 6;

        titleColumnNumber = logoSmall || checkIfParnerExistAndHaveImage ? 6 : 8;

        const logoSmallParner =
          logoSmall && HQ.Properties.agendaShowLogo == "1"
            ? `<div class="col-2 parnter-logo"><img class="img-fluid small-logo-partner" src="${getPathForVapor(
                logoSmall
              )}"></div>`
            : `<div class="col-2 parnter-logo"></div>`;

        const date = new Date(dateandtime).toLocaleDateString(
          HQ.Properties.lang,
          optionsForDay
        );

        const datePrevius = new Date(
          array[index - 1]?.dateandtime
        ).toLocaleDateString(HQ.Properties.lang, optionsForDay);

        const headerForFuture =
          agendaType == "future" && datePrevius !== date
            ? `<div class="row"><div class="p-2 programme-header">${date}</div></div>`
            : "";

        const placeID = place_id ? place_id : "";

        // title agenda
        const titleAgenda = title[HQ.Properties.lang] ?? title["en"];

        const dateJSONForCalendar = {
          name: titleAgenda,
          description: description
            ? description[HQ.Properties.lang] ?? description["en"]
            : "",
          startDate: getDate(dateandtime),
          startTime: getTime(dateandtime, false),
          endDate: getDate(dateandtime_end),
          endTime: getTime(dateandtime_end, false),
          placeID,
        };

        const dataPartner = partner ? `data-partner=${partner.name}` : "";

        return `
          <div class="container">
            ${headerForFuture}
            <div class="row program-row py-2 align-items-center" ${dataPartner} data-programme="${id}" data-show-on-map="${showOnMap}" data-twits="${place_id}">
              <div class="col-3 col-md-2 column-time">
                <strong class="text-nowrap">${getTime(
                  dateandtime,
                  true
                )}</strong>
              </div>
              ${logoSmallParner}
              <div class="col-5 col-md-6 title-programme">${titleAgenda}</div>
              <div class="col-2">
                <div class="text-center callendar-button" data-calendar='${JSON.stringify(
                  dateJSONForCalendar
                )}'>
                  ${svgIcon({ name: "icon-programme" })}
                </div>
              </div>
            </div>
          </div>
        `;
      }
    )
    .join("");

  return agendaType == "today"
    ? `<div class="programme-header p-2">${HQ.Properties.i18n.slider.today}</div>${programmeRow}`
    : programmeRow;
}

function getTime(isoTime, showLang) {
  const time = new Date(isoTime).toLocaleTimeString(
    showLang ? HQ.Properties.lang : [],
    {
      timeZone: HQ.Properties.timeZone ?? "UTC",
      timeStyle: "short",
    }
  );
  return time;
}

function getDate(isoDate) {
  const date = new Date(isoDate).toISOString().replace(/T.*/, "");
  return date;
}
