export const defaults = {
  iconSize: { w: 34, h: 34 },
  iconSizeForHole: { w: 24, h: 24 },
  key: HQ.Properties.keyFormMap,
  coordinates: HQ.Properties.mapCoordinates,
  customLink: HQ.Properties.golfCourseTiles,
  customAttribution:
    '<span>Event layer by <a href="https://www.eventhubhq.com/">Eventhub</a></span>',
  baseMapUrl: HQ.Properties.baseMapUrl,
  baseMapKey: HQ.Properties.baseMapKey,
  baseMapAttribution:
    'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  location: {
    denied: `<h4>${HQ.Properties.i18n.location.denied.first}</h4><p>${HQ.Properties.i18n.location.denied.second}</p>`,
    unavailable: `<p>${HQ.Properties.i18n.location.unavailable}</p>`,
    timeout: `<p>${HQ.Properties.i18n.location.timeout}</p>`,
    error: `<p>${HQ.Properties.i18n.location.error}</p>`,
  },
};

export const buttonQuickBar = [
  {
    icon: "info",
    i18n: "information",
    title: "Information",
    name: "information",
    className: "information-content",
    show: true,
  },
  {
    icon: "media",
    i18n: "highlights",
    title: "Highlights",
    name: "highlights",
    className: "highlights-content",
    show: true,
  },
  {
    icon: "programme",
    i18n: "programme",
    title: "Programme",
    name: "programme",
    className: "programme-content",
    show: true,
  },
  {
    icon: "search",
    i18n: "playerSearch",
    title: "Player search",
    name: "golf_player_search",
    className: "players-content",
    show: true,
  },
  {
    icon: "leaderboard",
    i18n: "leaderboard",
    title: "Leaderboard",
    name: "golf_leaderboard",
    className: "leaderboard-content",
    show: true,
  },
  {
    icon: "location",
    i18n: "location",
    title: "Location",
    name: "location",
    className: "location-content",
    sortNumber: 500,
    show: false,
  },
  // tennis
  {
    icon: "tennis-schedule",
    i18n: "schedule",
    title: "Schedule",
    name: "tennis_scheduled",
    className: "schedule-content",
    show: true,
  },
  {
    icon: "tennis-results",
    i18n: "results",
    title: "Results",
    name: "tennis_results",
    className: "results-content",
    show: true,
  },
  {
    icon: "matchplay-players",
    i18n: "matchplayPlayers",
    title: "Player search",
    name: "matchplay_players",
    className: "matchplay-players-content",
    show: true,
  },
  {
    icon: "matchplay-leaderboard",
    i18n: "matchplayLeaderboard",
    title: "Leaderboard",
    name: "matchplay_leaderboard",
    className: "matchplay-leaderboard-content",
    show: true,
  },
];

export const firstRoundHeader = [
  {
    classNames: "col-1 text-center top-five-position",
    text: "pos",
    i18n: "pos",
  },
  {
    classNames: "col-7 top-five-fullname",
    text: "name",
    i18n: "name",
  },
  {
    classNames: "col-1 text-truncate text-center top-five-country",
    text: "",
  },
  {
    classNames: "col-1 text-center top-five-total",
    text: "total",
    i18n: "total",
  },
  {
    classNames: "col-1 text-center top-five-thru",
    text: "thru",
    i18n: "thru",
  },
  {
    classNames: "col-1 text-center top-fav",
    text: "fav",
    i18n: "favorite",
  },
];

export const nextRoundsHeader = [
  {
    classNames: "col-1 text-center top-five-position",
    text: "pos",
    i18n: "pos",
  },
  {
    classNames: "col-5 top-five-fullname",
    text: "name",
    i18n: "name",
  },
  {
    classNames: "col-1 text-truncate text-center top-five-country",
    text: "",
    i18n: "",
  },
  {
    classNames: "col-1 text-center top-five-today",
    text: "today",
    i18n: "today",
  },
  {
    classNames: "col-2 text-center top-five-total",
    text: "total",
    i18n: "total",
  },
  {
    classNames: "col-1 text-center top-five-thru",
    text: "thru",
    i18n: "thru",
  },
  {
    classNames: "col-1 text-center top-fav",
    text: "fav",
    i18n: "favorite",
  },
];

export const additionalElementsAtTheBottomOfTheMap = [
  {
    position: "bottomleft",
    icon: '<div class="extra-icon icon-home"><svg><use href="#icon-eventhub-home"></use></svg></div>',
    className: "home-eventhubhq",
  },
  {
    position: "bottomright",
    icon: '<div class="extra-icon icon-infor"><svg><use href="#icon-info"></use></svg></div>',
    className: "content-information",
  },
];

export const defaultInformationSettings = {
  allLayers: {
    id: 99,
    name: "Show all layers",
    icon: "icon-show-all-layers",
    color: null,
    description: "show-all-layers",
    children: [],
  },
  allGroups: {
    id: 0,
    name: "Groups",
    icon: "icon-flights",
    color: "#ff0000",
    description: "groups",
    children: [],
  },
};

export const teamColor = {
  eur: {
    main: {
      color: "#003C82",
    },
    secondary: {
      color: "#02245D",
    },
  },
  usa: {
    main: {
      color: "#C81414",
    },
    secondary: {
      color: "#A10A0A",
    },
  },
};

export const timelineWrapper = `
<div class="timeline-wrapper">
    <div class="timeline-item">
        <div class="animated-background">
            <div class="background-masker header-top"></div>
            <div class="background-masker header-left"></div>
            <div class="background-masker header-right"></div>
            <div class="background-masker header-bottom"></div>
            <div class="background-masker subheader-left"></div>
            <div class="background-masker subheader-right"></div>
            <div class="background-masker subheader-bottom"></div>
            <div class="background-masker content-top"></div>
            <div class="background-masker content-first-end"></div>
            <div class="background-masker content-second-line"></div>
            <div class="background-masker content-second-end"></div>
            <div class="background-masker content-third-line"></div>
            <div class="background-masker content-third-end"></div>
        </div>
    </div>
</div>`;
